exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-rent-js": () => import("./../../../src/pages/cennik/rent.js" /* webpackChunkName: "component---src-pages-cennik-rent-js" */),
  "component---src-pages-embeded-js": () => import("./../../../src/pages/Embeded.js" /* webpackChunkName: "component---src-pages-embeded-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kratkodobe-js": () => import("./../../../src/pages/kratkodobe.js" /* webpackChunkName: "component---src-pages-kratkodobe-js" */),
  "component---src-pages-kurzy-js": () => import("./../../../src/pages/kurzy.js" /* webpackChunkName: "component---src-pages-kurzy-js" */),
  "component---src-pages-servis-js": () => import("./../../../src/pages/servis.js" /* webpackChunkName: "component---src-pages-servis-js" */),
  "component---src-pages-sezonne-js": () => import("./../../../src/pages/sezonne.js" /* webpackChunkName: "component---src-pages-sezonne-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

